import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./widget-handle");
  require("./modal");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $("#home-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    autoplay: true,
    focusOnSelect: false,
    focusOnChange: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 1000,
  });
});

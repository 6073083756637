const widget = document.getElementById("widget-svg-step1");

if (widget) {
  $.request("flatsList::onGetFlatsJson", {
    success: function(data) {
      const obj = JSON.parse(data.result);
      //convert object data to array
      function* values(obj) {
        for (let prop of Object.keys(obj)) yield obj[prop];
      }
      let cmsFlats = Array.from(values(obj));

      const buildings = [...document.getElementsByClassName("widget-building")];
      const buildingsStep1 = [
        ...document.getElementsByClassName("step1-building"),
      ];
      const floorsStep1 = [...document.getElementsByClassName("step1-floor")];

      const siteWrapper = document.getElementsByClassName("site-wrapper")[0];

      //floor tooltip
      const tooltip = document.getElementById("widget-tooltip");
      const tooltipCounter = document.getElementById("widget-tooltip-counter");
      siteWrapper.appendChild(tooltip);

      //modal step1
      const modalStep1 = document.getElementById("widget-modal-step1");
      const modalCloseStep1 = document.getElementById("modal-close-step1");
      siteWrapper.appendChild(modalStep1);

      //modal close handle
      modalCloseStep1.addEventListener("click", function(e) {
        e.preventDefault();
        modalStep1.classList.remove("is-open");
        document.body.classList.remove("modal-open");
        buildingsStep1.forEach((buildingStep1) => {
          buildingStep1.classList.remove("active");
        });
      });

      //actions on building click
      const modalHeadStep1 = document.getElementById("widget-modal-head-step1");
      buildings.forEach((building) => {
        const modalBuilding = building.dataset.building;
        const modalBuildingId = building.dataset.id;

        building.addEventListener("click", function(e) {
          e.preventDefault();

          const buildingEnable = building.dataset.enable;
          if (buildingEnable == "true") {
            modalHeadStep1.textContent = modalBuilding;
            buildingsStep1.forEach(function(buildingStep1) {
              if (
                buildingStep1.dataset.building === building.dataset.building
              ) {
                buildingStep1.classList.add("active");
                const floorsInBuilding = [
                  ...$(buildingsStep1).find(".step1-floor"),
                ];

                floorsInBuilding.forEach(function(floorStep1) {
                  const flatsOnFloor = cmsFlats.filter(
                    (flat) =>
                      flat.building_id === parseInt(modalBuildingId) &&
                      flat.floor_id === parseInt(floorStep1.dataset.floor)
                  );

                  floorStep1.addEventListener("mouseenter", function() {
                    tooltip.style.transform = "scale(1)";
                    tooltip.style.opacity = "1";
                    tooltipCounter.textContent = flatsOnFloor.length;
                  });
                  floorStep1.addEventListener("mousemove", function(e) {
                    const left = parseInt(e.pageX) + 30 + "px";
                    const top = parseInt(e.pageY) + 30 + "px";

                    tooltip.style.left = left;
                    tooltip.style.top = top;
                  });
                  floorStep1.addEventListener("mouseleave", function() {
                    tooltip.style.opacity = "0";
                    tooltip.style.transform = "scale(0)";
                  });

                  floorStep1.addEventListener("click", function(e) {
                    e.preventDefault();

                    const selectedFloorId = e.target.dataset.floor;

                    const step2Floors = [...$(buildingStep1).find(".floor")];

                    const selectedFloor = step2Floors.find(
                      (item) => item.dataset.floor === selectedFloorId
                    );

                    const modalStep2 = [
                      ...$(buildingStep1).find(".widget-modal-step2"),
                    ][0];

                    const tooltipStep2 = [
                      ...$(buildingStep1).find(".widget-tooltip-step2"),
                    ][0];

                    const flatsStep2 = [...$(selectedFloor).find(".flat")];

                    const counterApartment = $(buildingStep1).find(
                      ".counter-apartment"
                    )[0];

                    const counterRooms = $(buildingStep1).find(
                      ".counter-rooms"
                    )[0];

                    const counterSize = $(buildingStep1).find(
                      ".counter-size"
                    )[0];

                    const previewAvailability = $(buildingStep1).find(
                      ".preview-availability"
                    )[0];

                    const closeStep2 = $(buildingStep1).find(
                      ".modal-close-step2"
                    )[0];

                    //set floor name
                    let floorName;
                    if (flatsOnFloor[0].floor_id === 1) {
                      floorName = "parter";
                    } else if (flatsOnFloor[0].floor_id === 2) {
                      floorName = "pierwsze";
                    } else if (flatsOnFloor[0].floor_id === 3) {
                      floorName = "drugie";
                    } else if (flatsOnFloor[0].floor_id === 4) {
                      floorName = "trzecie";
                    } else if (flatsOnFloor[0].floor_id === 5) {
                      floorName = "czwarte";
                    }
                    $(buildingStep1)
                      .find("#widget-modal-head-step2")
                      .text(floorName);

                    //step2 flat tooltip

                    flatsStep2.forEach((flat) => {
                      const flatId = flat.id;

                      const findFlat = cmsFlats.find(
                        (cmsFlat) => cmsFlat.symbol === flatId
                      );

                      if (findFlat) {
                        flat.addEventListener("click", function(e) {
                          e.preventDefault();
                          if (findFlat.status === "wolne") {
                            window.location = `/mieszkania/${findFlat.id}/${findFlat.slug}`;
                          }
                        });
                        flat.addEventListener("mouseenter", function() {
                          tooltipStep2.style.transform = "scale(1)";
                          tooltipStep2.style.opacity = "1";

                          if (findFlat.name) {
                            counterApartment.textContent = findFlat.symbol;
                            counterApartment.parentElement.style.display =
                              "block";
                          } else {
                            counterApartment.parentElement.style.display =
                              "none";
                          }

                          if (findFlat.rooms_number) {
                            counterRooms.textContent = findFlat.rooms_number;
                            counterRooms.parentElement.style.display = "block";
                          } else {
                            counterRooms.parentElement.style.display = "none";
                          }

                          if (findFlat.area) {
                            counterSize.textContent = findFlat.area + " \u33A1";
                            counterSize.parentElement.style.display = "block";
                          } else {
                            counterSize.parentElement.style.display = "none";
                          }

                          if (findFlat.status) {
                            previewAvailability.textContent = findFlat.status;
                            previewAvailability.parentElement.style.display =
                              "block";
                          } else {
                            previewAvailability.parentElement.style.display =
                              "none";
                          }
                        });
                        flat.addEventListener("mousemove", function(e) {
                          const left = parseInt(e.clientX) + 30 + "px";
                          const top = parseInt(e.clientY) + 30 + "px";
                          tooltipStep2.style.left = left;
                          tooltipStep2.style.top = top;
                        });
                        flat.addEventListener("mouseleave", function() {
                          tooltipStep2.style.opacity = "0";
                          tooltipStep2.style.transform = "scale(0)";
                        });
                      }
                    });

                    //set flats status
                    flatsStep2.forEach((flat) => {
                      const flatId = flat.id;
                      const findFlat = cmsFlats.find(
                        (cmsFlat) => cmsFlat.symbol === flatId
                      );
                      if (findFlat) {
                        if (findFlat.status === "wolne") {
                          flat.classList.add("widget__flat-status--green");
                        } else if (findFlat.status === "zarezerwowane") {
                          flat.classList.add("widget__flat-status--yellow");
                        } else {
                          flat.classList.add("widget__flat-status--red");
                        }
                      } else {
                        flat.classList.add("widget__flat-status--red");
                      }
                    });

                    //open step2

                    modalStep2.classList.add("is-open");
                    step2Floors.forEach(function(element) {
                      if (element.dataset.floor === selectedFloorId) {
                        element.classList.add("active");
                      } else {
                        element.classList.remove("active");
                      }
                    });

                    //close step2

                    closeStep2.addEventListener("click", function(e) {
                      e.preventDefault();
                      modalStep2.classList.remove("is-open");
                    });
                  });
                });
              } else {
                buildingStep1.classList.remove(".active");
              }
            });

            modalStep1.classList.toggle("is-open");
            document.body.classList.toggle("modal-open");
          }
        });
      });
    },
  });
}
